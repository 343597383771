import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const WigforWomen = () => {
  const seoData = {
    title: "Wigs for Women in Delhi - Radiance Hair Studio",
    description:
      "Explore a stunning range of Wigs for Women in Delhi at Radiance Hair Studio. Find the perfect wig for any occasion, tailored to match your style.",
    keywords: ["Wigs for Women in Delhi"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/wigs-for-womens.png",
      "https://www.radiancehairstudio.com/wigs-for-womens1.png",
      "https://www.radiancehairstudio.com/wigs-for-womens2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "Wigs for Women in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/wigs-for-womens.php",
    "category": "https://www.radiancehairstudio.com/wigs-for-womens.php",
    "description": "Explore a stunning range of Wigs for Women in Delhi at Radiance Hair Studio. Find the perfect wig for any occasion, tailored to match your style.",
    "serviceType": "Wig for Women's"
  };  

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
      img: "/wigs-for-womens.png",
      content: (
        <div>
          <h2>Top Quality WIG for Women's</h2>
          <p>
            Women have always chosen to reflect their personality and vibes
            through what they wear? and how they wear! It’s simply like YOU ARE
            WHAT YOU WEAR! But the lifestyle we choose has different plans may
            be. Hair fall and thinning are the two most common Devils enough to
            ruin the MANE MOOD.
          </p>
          <h2>Masteter Crafted Wigs Only in Radiance!</h2>
          <p>
            Oh, Wait! WE ARE BORN FIGHTERs too. And to fight all the hair hazard
            WIGS to rescue! Be it the <a href="/about.php" style={{ color: "#373737"}}>Lifestyle</a> Monster or that Devil Disease
            that's ruining the scenario, RADIANCE has got your back.
          </p>
          <p>
            Get your hands on the best of WIGS that can reflect the YOU! At
            RADIANCE we help our brave women to get what they DESERVE. Short,
            long, thick or thin, black or colored, you say and we have it, but
            only the ALL NATURAL HUMAN HAIR!
          </p>
          <p>
            Fakeness is not something one should ever carry inside out. The same
            reason leads RADIANCE to offer the natural WIGS to the REAL women.
          </p>
        </div>
      ),
    },
  ];

  const sectionS3 = [
    {
      smallImg: "/wigs-for-womens1.png",
      largeImg: "/wigs-for-womens2.png",
      content: (
        <div>
          <h2>Benefits: The Big Blessings!</h2>
          <p>
            The DIVINE heard it all and thus WIGS! WIGs are indeed blessings in
            disguise. Letting you shower your love also with the natural feel
            can be named sheer LOVE. Our hair contributes to physical beauty,
            boosting our confidence and letting us rule the world. Here are some
            of them.
          </p>
          <ul>
            <li>
              <b>STYLE</b>- Our crown projects the personality we carry. The
              mane also contributes to adjusting our physical appearance
              accordingly. Human hair wigs let you do the same absolutely in no
              time!
            </li>
            <li>
              <b>NEED</b>- Perfection is possessed in every individual in
              different forms. Some of us are not blessed with a strong MANE
              GAME! Wig resolves the issue meeting our biological needs.{" "}
            </li>
            <li>
              <b>LOOK</b>- The character you carry reflects through the physical
              appearance you project. Every designation has a sorted look to be
              projected. WIGs help one to change their look as per choices
              maintaining the versatility.{" "}
            </li>
            <li>
              <b>LIFE</b>- Wigs usually involves money matters. Taking care of
              your POCKET PINCH natural human hair wigs come with a longer
              lifetime.{" "}
            </li>
          </ul>
          <p>
            At RADIANCE we offer the best for our visitor. With the quality and
            100% natural human <a href="hair-replacement-for-men.php" style={{ color: "#373737"}}>hair wig</a>, we try to make your experience
            beautiful.
          </p>
        </div>
      ),
    },
  ];

  const sectionS4 = [
    {
      title: "FAQs: Answer Yourself!",
      description:
        "Wearing a WIG is not something we are habitual with. And the money matters may make you think several questions. We are here to answer all of that.",
      description2:
        "Hopefully, the answers will be enough to clear all your queries. RADIANCE HAIR STUDIO is always to your help with a free consultation from experts.",
      faq: [
        {
          title:
            "Do I need a wig if facing partial hair loss or thinning hair?",
          description:
            "There are several wigs available that potentially hide the thinning hairline or hair loss areas and one can hardly identify the wig as it blends seamlessly with your existing hair. It makes your hair looks flawless and gorgeous. Moreover, with our wigs what is made of 100% natural human hair, you will have a natural feel and finish.",
        },
        {
          title: "Can I style my Wig?",
          description:
            "You can of course style your wig according to your choice. But as maintenance is an important step, it is advisable not to be harsh on the wig you wear. RADIANCE also offers with a huge collection of premium pre-customized wigs for customers.",
        },
        {
          title: "What quality wigs does Radiance provide?",
          description:
            "Though we have a collection of all kinda wigs in high quality, we always do recommend and assist our customers to go for wigs what is exclusively made of 100% natural human hair. Here in Radiance, we will customize your wig as per your preference",
        },
        {
          title: "What color should I choose? Can I customize it?",
          description:
            "We have a catalog of wigs specifically for styles and colors. Moreover, our hair experts will guide you to pick a proper color and perfect style that suits you the most. And yes, we will customize the color of the wig for you as per your own choice and it will be completely looked like your natural hair.",
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wig for Women's" banner="/wig-for-women.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Top Quality WIG for Women's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default WigforWomen;
